import { GEOJSON_SERVER } from 'config/base';
import { LayerProps } from 'react-map-gl';
import { atom, selector, selectorFamily } from 'recoil';
import { ApiCallStatus, MapLayerConfig } from 'types';

import { rsmMapLayersVisibility } from '../mapLayers';

/**
 * The map layer configs available for the current user
 */
export const rsmMapLayerConfigs = atom<MapLayerConfig[]>({
  key: 'rsmMapLayerConfigs',
  default: [],
});

/**
 * @description The status of fetching map layer configs
 */
export const rsmFetchMapLayerConfigStatus = atom<ApiCallStatus>({
  key: 'rsmFetchMapLayerConfigStatus',
  default: ApiCallStatus.Pending,
});

/**
 * Returns whether there are dynamic layers
 */
export const rsmHasDynamicLayers = selector({
  key: 'rsmHasDynamicLayers',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);

    return !!mapLayerConfigs.length;
  },
});

/**
 * @description These are the ids of all the dynamic layers
 */
export const rsmDynamicLayerIds = selector({
  key: 'rsmDynamicLayerIds',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);

    return mapLayerConfigs.map((mapLayerConfig) => mapLayerConfig.id);
  },
});

/**
 * @description Returns a map of location names to mapLayerConfig ids
 */
export const rsmDynamicLayerLocationToIdsMap = selector<{ [key: string]: MapLayerConfig['id'] }>({
  key: 'rsmDynamicLayerLocationToIdsMap',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);

    return mapLayerConfigs.reduce((locationIdMap, mapLayerConfig) => {
      const localMap = mapLayerConfig.config.source.location.reduce((map, locationName) => {
        return {
          ...map,
          [locationName]: mapLayerConfig.id,
        };
      }, {});

      return {
        ...locationIdMap,
        ...localMap,
      };
    }, {});
  },
});

/**
 * This is an array of all the ids for visible dynamic map layers
 */
export const rsmVisibleDynamicLayerIds = selector({
  key: 'rsmVisibleDynamicLayerIds',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);
    const layerVisibility = get(rsmMapLayersVisibility);

    return mapLayerConfigs.map((mapLayerConfig) => mapLayerConfig.id).filter((id) => !!layerVisibility[id]);
  },
});

/**
 * @description Returns a prioritized list of the maplayer ids sorted by popupInfoPriority
 */
export const rsmDynamicLayerPrioritization = selector({
  key: 'rsmDynamicLayerPrioritization',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);
    const newMapLayerConfigs = [...mapLayerConfigs];

    newMapLayerConfigs.sort((firstConfig, secondConfig) => {
      return firstConfig.config.displayInfo.popupInfoPriority - secondConfig.config.displayInfo.popupInfoPriority;
    });

    return newMapLayerConfigs.map((mapLayerConfig) => mapLayerConfig.id);
  },
});

/**
 * @description Returns the Map Layer Config with the given id
 */
export const rsmMapLayerConfigById = selectorFamily<MapLayerConfig, MapLayerConfig['id']>({
  key: 'rsmMapLayerConfigById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfigs = get(rsmMapLayerConfigs);

      return mapLayerConfigs.find((mapLayerConfig) => mapLayerConfig.id === mapLayerConfigId);
    },
});

/**
 * @description Returns the template type for the Map Layer Config with the given id
 */
export const rsmTemplateTypeById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmTemplateTypeById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config?.layerTemplate?.name;
    },
});

/**
 * @description Returns the main color to use for the styling template for the Map Layer Config with the given id
 */
export const rsmTemplateColorById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmTemplateColorById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config?.layerTemplate?.color;
    },
});

/**
 * @description Returns the selected color to use for the styling template for the Map Layer Config with the given id
 */
export const rsmTemplateSelectedColorById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmTemplateSelectedColorById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config?.layerTemplate?.selectedColor;
    },
});

/**
 * @description Returns the promote id for the Map Layer Config with the given id
 */
export const rsmPromoteIdById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmPromoteIdById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config.source.promoteId;
    },
});

/**
 * @description Returns the custom styling objects for the layer
 */
export const rsmLayerStylingById = selectorFamily<LayerProps[], MapLayerConfig['id']>({
  key: 'rsmLayerStylingById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config?.layers;
    },
});

/**
 * @description Returns the tiles array for the layer
 */
export const rsmTilesById = selectorFamily<string[], MapLayerConfig['id']>({
  key: 'rsmTilesById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));
      const tilesPathname = mapLayerConfig?.config?.source?.location?.reduce((path, bucketName) => {
        if (!path) {
          return bucketName;
        }

        return `${path}+${bucketName}`;
      });

      if (!mapLayerConfig) {
        return [];
      }

      return [`${GEOJSON_SERVER}/${tilesPathname}/latest/{z}/{x}/{y}.pbf`];
    },
});

/**
 * @description Returns the icon type to use for the Map Layer with the given id
 */
export const rsmMapIconById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmMapIconById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config?.layerTemplate?.icon;
    },
});

/**
 * @description Returns the title for the given MapLayerConfig
 */
export const rsmTitleById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmTitleById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig?.config?.displayInfo?.title || '';
    },
});

/**
 * @description Returns the subtitle for the given MapLayerConfig
 */
export const rsmSubTitleById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmSubTitleById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config.displayInfo.subTitle || '';
    },
});

/**
 * @description Returns the icon to use for the given MapLayerConfig
 */
export const rsmIconById = selectorFamily<string, MapLayerConfig['id']>({
  key: 'rsmIconById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig.config.displayInfo.icon;
    },
});

/**
 * @description Get Map Layer Config Id by the name of the location
 */
export const rsmMapLayerConfigIdByLocation = selectorFamily<MapLayerConfig['id'], string>({
  key: 'rsmMapLayerConfigIdByLocation',
  get:
    (mapLayerLocation) =>
    ({ get }) => {
      const locationToIdMap = get(rsmDynamicLayerLocationToIdsMap);

      return locationToIdMap[mapLayerLocation];
    },
});

/**
 * @description Related to Giving info for each nearby asset in the Nearby Asset List
 * @returns an array of keys for all the properities to display information
 */
export const rsmNearbyAssetInfoById = selectorFamily<string[], string>({
  key: 'rsmNearbyAssetInfoById',
  get:
    (mapLayerConfigId) =>
    ({ get }) => {
      const mapLayerConfig = get(rsmMapLayerConfigById(mapLayerConfigId));

      return mapLayerConfig?.config?.displayInfo?.properties?.map((property) => property.property.toString()) || [];
    },
});

/**
 * @returns An array of 'locations' for all the map layer configs that have the Nearby Assets functionality enabled
 */
export const rsmNearByAssetEligibleLocations = selector<string[]>({
  key: 'rsmNearByAssetEligibleLocations',
  get: ({ get }) => {
    const mapLayerConfigs = get(rsmMapLayerConfigs);

    return mapLayerConfigs?.reduce((configLocations, currentConfig) => {
      if (!currentConfig.config.isNearbyAssetsEnabled) {
        return configLocations;
      }

      return [...configLocations, ...(currentConfig?.config?.source?.location || [])];
    }, []);
  },
});
